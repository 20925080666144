import { useLayoutEffect, useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import ProductService from '../../services/ProductService';
import PopupService from '../../services/PopupService';
import YextService from '../../services/YextService';
import { Defaults, priceColumnsConfig, Utils } from '../../helpers';
import { createClubPrices, IAgreementPriceDetails, ILocation } from '../../models';

const ClubGroupPrices = () => {
  const columns = useMemo<MRT_ColumnDef<ILocation>[]>(() => priceColumnsConfig as MRT_ColumnDef<ILocation>[], []);
  const { clubs, group } = useLoaderData() as { clubs: ILocation[], group: string };
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: clubs,
    enableHiding: false,
    enablePagination: false,
    enableStickyHeader: true,
    initialState: { density: 'compact' },
    muiTableBodyProps: { sx: { '& tr:nth-of-type(odd)': { backgroundColor: '#eee' } } },
    muiTableContainerProps: { sx: { maxHeight: tableHeight } },
    muiTableProps: { sx: { tableLayout: 'fixed' } }
  });

  return (
    <div className='clubs-container'>
      <div className='header'>
        <div className='title'>{Utils.getRegionName(clubs[0].isoRegionCode)} ({group.toUpperCase()})</div>
        <div className='info'>Total Clubs: <strong>{clubs.length}</strong></div>
      </div>
      <MaterialReactTable table={table} />
    </div>
  )
}

export const clubGroupPricesLoader = async (request: any) => {
  PopupService.showLoader('Getting Prices');
  const { id: regionId, group } = request.params;
  const { response: { entities } } = await YextService.getFacilitiesByRegion(regionId);
  const [from, to] = group.split('-');
  let clubGroup = [];
  if (to) {
    const regex = new RegExp(`^[${from}-${to}${from.toUpperCase()}-${to.toUpperCase()}].*$`);
    for (const club of entities) {
      const firstCharacter = club.geomodifier[0];
      if (regex.test(firstCharacter)) {
        clubGroup.push(club);
      }
    }
  } else {
    clubGroup = entities.filter(entity => entity.c_clubType === group.toUpperCase());
  }
  const clubs = await fillClubDetails(clubGroup);
  PopupService.hideLoader();
  return { clubs, group };
}

const fillClubDetails = async (clubs: ILocation[]): Promise<ILocation[]> => {
  const response = [];
  for (const club of clubs) {
    let agreementsPrices: IAgreementPriceDetails[];
    try {
      const planPrices = await ProductService.getPrices(club.meta.id);
      agreementsPrices = planPrices.prices;
    } catch {
      agreementsPrices = [];
    }
    const prices = createClubPrices(agreementsPrices);
    response.push({ ...club, prices });
  }
  return response;
};

export default ClubGroupPrices;