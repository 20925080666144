import { createSlice } from '@reduxjs/toolkit';
import { LocationsConfigEvents } from '../helpers';
import { LocationConfig, TEvent, TLocationConfiguration } from '../models';

export type LocationsConfigState = {
  items: TLocationConfiguration[];
  facilityConfig: any;
  event: TEvent;
  changed: boolean;
}
const initialState: LocationsConfigState = { items: [], changed: false, facilityConfig: { ...new LocationConfig() }, event: LocationsConfigEvents.view };

const locationsConfigSlice = createSlice({
  name: 'locationsConfig',
  initialState,
  reducers: {
    replaceLocationsConfig(state, action) {
      state.items = action.payload;
    },
    updateLocationConfig(state, action) {
      state.items[action.payload.index].adyenInformation = {
        clientKey: action.payload.values['Client Key'],
        environment: action.payload.values['Environment'],
        locale: action.payload.values['Locale'],
        merchantAccount: action.payload.values['Merchant Account']
      }
    },
    removeLocationConfig(state, action) {
      delete state.items[action.payload].adyenInformation;
    }
  }
});
export const locationsConfigActions = locationsConfigSlice.actions;

export default locationsConfigSlice.reducer;