import { IFacilityListData, PriceItem } from '.';
import { ButtonLabel, LoaderLabel, PlanAction, PlanStatus, Utils } from '../helpers';

export interface IPlanPrices {
    Green: IPlanPrice;
    Blue: IPlanPrice;
    Gray: IPlanPrice;
    Orange: IPlanPrice;
}

export interface IPlanPrice {
    ItemPrice: string | number;
    PromoPrice: string | number;
    AgreementPrice: string | number;
}

export class PlanPrices implements IPlanPrices {
    Green: IPlanPrice;
    Blue: IPlanPrice;
    Gray: IPlanPrice;
    Orange: IPlanPrice;

    constructor(data?: any) {
        this.Green = new PlanPrice(data?.Green);
        this.Blue = new PlanPrice(data?.Blue);
        this.Gray = new PlanPrice(data?.Gray);
        this.Orange = new PlanPrice(data?.Orange);
    }
}

class PlanPrice implements IPlanPrice {
    ItemPrice: string;
    PromoPrice: string;
    AgreementPrice: string;

    constructor(data?: any) {
        this.ItemPrice = data ? data.ItemPrice : '-'
        this.PromoPrice = data ? data.PromoPrice : '-'
        this.AgreementPrice = data ? data.AgreementPrice : '-'
    }
}

export class Product {
    private _bundleMonthlyFee: string | number;
    private _bundleStartupFee: string | number;
    private _defaultPromotion: string;
    private _planStatus: PlanStatus;
    private _promotionMonthlyFee: string | number;
    private _promotionStartupFee: string | number;
    private _region: string;
    private _id: string;
    private _name: string;
    private _loaderLabel: LoaderLabel;
    private _buttonLabel: ButtonLabel;

    constructor(facility: IFacilityListData, selectedRegion: string) {
        this._bundleMonthlyFee = '-';
        this._bundleStartupFee = '-';
        this._defaultPromotion = '-';
        this._planStatus = PlanStatus.INACTIVE;
        this._promotionMonthlyFee = '-';
        this._promotionStartupFee = '-';
        this._region = Utils.getRegionName(selectedRegion);
        this._id = facility.id;
        this._name = facility.name;
        this._loaderLabel = LoaderLabel.ENABLING;
        this._buttonLabel = ButtonLabel.ENABLE;
    }

    /**
     * Setter for monthly & startup fees
     * @param type 
     * @param clubPrice 
     */
    updateFees(clubPrice: PriceItem): void {
        if (clubPrice.isStartupFee) {
            this._bundleStartupFee = clubPrice.unitPrice;
            this._promotionStartupFee = clubPrice.total;
        } else if (clubPrice.isMonthlyFee) {
            this._bundleMonthlyFee = clubPrice.unitPrice;
            this._promotionMonthlyFee = clubPrice.total;
        }
        this._loaderLabel = LoaderLabel.DISABLING;
        this._buttonLabel = ButtonLabel.DISABLE;
    }

    /**
     * Getter for bundleMonthlyFee
     * @returns {string | number}
     */
    get bundleMonthlyFee(): string | number {
        return this._bundleMonthlyFee;
    }

    /**
     * Setter for bundleMonthlyFee
     * @param {string | number} value
     */
    set bundleMonthlyFee(value: string | number) {
        this._bundleMonthlyFee = value;
    }

    /**
     * Getter for bundleStartupFee
     * @returns {string | number}
     */
    get bundleStartupFee(): string | number {
        return this._bundleStartupFee;
    }

    /**
     * Setter for bundleStartupFee
     * @param {string | number} value
     */
    set bundleStartupFee(value: string | number) {
        this._bundleStartupFee = value;
    }

    /**
     * Getter for defaultPromotion
     * @returns {string}
     */
    get defaultPromotion(): string {
        return this._defaultPromotion;
    }

    /**
     * Setter for defaultPromotion
     * @param {string} value
     */
    set defaultPromotion(value: string) {
        this._defaultPromotion = value;
    }

    /**
     * Getter for planStatus
     * @returns {PlanStatus}
     */
    get planStatus(): PlanStatus {
        return this._planStatus;
    }

    /**
     * Setter for planStatus
     * @param {PlanStatus} value
     */
    set planStatus(value: PlanStatus) {
        this._planStatus = value;
    }

    /**
     * Getter for promotionMonthlyFee
     * @returns {string | number}
     */
    get promotionMonthlyFee(): string | number {
        return this._promotionMonthlyFee;
    }

    /**
     * Setter for promotionMonthlyFee
     * @param {string | number} value
     */
    set promotionMonthlyFee(value: string | number) {
        this._promotionMonthlyFee = value;
    }

    /**
     * Getter for promotionStartupFee
     * @returns {string | number}
     */
    get promotionStartupFee(): string | number {
        return this._promotionStartupFee;
    }

    /**
     * Setter for promotionStartupFee
     * @param {string | number} value
     */
    set promotionStartupFee(value: string | number) {
        this._promotionStartupFee = value;
    }

    /**
     * Getter for region
     * @returns {string}
     */
    get region(): string {
        return this._region;
    }

    /**
     * Setter for region
     * @param {string} value
     */
    set region(value: string) {
        this._region = value;
    }

    /**
     * Getter for id
     * @returns {string}
     */
    get id(): string {
        return this._id;
    }

    /**
     * Setter for id
     * @param {string} value
     */
    set id(value: string) {
        this._id = value;
    }

    /**
     * Getter for name
     * @returns {string}
     */
    get name(): string {
        return this._name;
    }

    /**
     * Setter for name
     * @param {string} value
     */
    set name(value: string) {
        this._name = value;
    }

    /**
     * Getter for loaderLabel
     * @returns {LoaderLabel}
     */
    get loaderLabel(): LoaderLabel {
        return this._loaderLabel;
    }

    /**
     * Setter for loaderLabel
     * @param {LoaderLabel} value
     */
    set loaderLabel(value: LoaderLabel) {
        this._loaderLabel = value;
    }

    /**
     * Getter for buttonLabel
     * @returns {ButtonLabel}
     */
    get buttonLabel(): ButtonLabel {
        return this._buttonLabel;
    }

    /**
     * Setter for buttonLabel
     * @param {ButtonLabel} value
     */
    set buttonLabel(value: ButtonLabel) {
        this._buttonLabel = value;
    }
}

export interface IUpdatePlanPayload {
    action: PlanAction;
    bundleName: string;
    location: string;
    productName: string;
}

export interface IUpdateBundleStatusResponse {
    success: boolean;
    message?: string;
}

export type TLocationConfiguration = {
    sourceId?: number;
    sourceName?: string;
    location: string;
    name?: string;
    division?: unknown;
    taxRates?: unknown;
    timeZone?: string;
    adyenInformation?: TAdyenConfiguration;
}

type TAdyenConfiguration = {
    environment: string;
    clientKey: string;
    locale: string;
    merchantAccount: string;
}

export class LocationConfig implements TLocationConfiguration {
    sourceId: number;
    sourceName: string;
    location: string;
    name: string;
    division: unknown;
    taxRates: unknown;
    timeZone: string;
    adyenInformation: TAdyenConfiguration;

    constructor(data?: TLocationConfiguration) {
        this.sourceId = data?.sourceId || 0;
        this.sourceName = data?.sourceName || '';
        this.location = data?.location || '';
        this.name = data?.name || '';
        this.division = data?.division;
        this.taxRates = data?.taxRates || 0;
        this.timeZone = data?.timeZone || '';
        this.adyenInformation = {
            environment: data?.adyenInformation?.environment || '',
            clientKey: data?.adyenInformation?.clientKey || '',
            locale: data?.adyenInformation?.locale || '',
            merchantAccount: data?.adyenInformation?.merchantAccount || ''
        }
    }
}


