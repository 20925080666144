import { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PromoCodeEvents, Utils } from '../helpers';
import { IPromoCode, PromoCode } from "../models";

const PromoCodeForm = (props: any) => {
  const { event, promoCode } = useSelector((state: any) => state.promoCodes);
  const [form, setForm] = useState(promoCode);
  const [startDate, setStartDate] = useState(promoCode.startDate);
  const [endDate, setEndDate] = useState(promoCode.endDate);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { id, value } } = event;
    setForm((prevState: any) => ({ ...prevState, [id]: value }));
  };

  const handleStartDateChange = (newValue: Date | null) => {
    if (newValue) {
      const formattedStartDate = Utils.getFormattedDate(newValue);
      setStartDate(formattedStartDate);
    }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue) {
      const formattedEndDate = Utils.getFormattedDate(newValue);
      setEndDate(formattedEndDate);
    }
  };

  const handleSubmit = async () => {
    const data = {
      ...form,
      endDate,
      startDate
    } as unknown as IPromoCode;
    resetForm();
    props.handleSubmit(data);
  };

  const handleCancel = async () => {
    resetForm();
    props.handleClose();
  };

  const resetForm = () => {
    setForm(new PromoCode());
    setStartDate(Utils.getFormattedDate());
    setEndDate(Utils.getFormattedDate());
  }

  useEffect(() => {
    setForm(promoCode);
    setStartDate(promoCode.startDate);
    setEndDate(promoCode.endDate);
  }, [promoCode])

  return (
    <Dialog fullWidth maxWidth="xl" open={props.open} onClose={props.handleClose} data-testid='promocode-form'>
      <DialogTitle>{event.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{event.text}</DialogContentText>
        <Row className="text-center justify-content-center">
          <Col lg={8}>
            <Row className="mt-3">
              <Col lg={4}>
                <FormControl fullWidth>
                  <TextField disabled={event.type === PromoCodeEvents.update.type} onChange={handleInputChange} value={form.promoCode} label="Promo Code Name" id="promoCode" margin="dense" aria-describedby="promo-code-helper-text" />
                </FormControl>
              </Col>
              <Col lg={4}>
                <FormControl fullWidth>
                  <TextField onChange={handleInputChange} value={form.mosoPromoName} label="MOSO Promotion Name" id="mosoPromoName" margin="dense" aria-describedby="promo-name-helper-text" />
                </FormControl>
              </Col>
              <Col lg={4}>
                <FormControl fullWidth>
                  <TextField onChange={handleInputChange} value={form.promoMosoId} label="MOSO Promotion Id" id="promoMosoId" margin="dense" aria-describedby="promo-id-helper-text" />
                </FormControl>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={new Date(startDate)}
                      onChange={handleStartDateChange}
                      slots={{
                        textField: textFieldProps => <TextField
                          {...textFieldProps} id="startDate" margin="dense" aria-describedby="start-date-helper-text"
                        />
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Col>
              <Col lg={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={new Date(endDate)}
                      onChange={handleEndDateChange}
                      slots={{
                        textField: textFieldProps => <TextField
                          {...textFieldProps} id="endDate" margin="dense" aria-describedby="end-date-helper-text"
                        />
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Col>
              <Col lg={4}>
                <FormControl fullWidth>
                  <TextField onChange={handleInputChange} value={form.maxUseCount} label="Maximum Use Count" id="maxUseCount" margin="dense" aria-describedby="max-use-count-helper-text" />
                </FormControl>
              </Col>
            </Row>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="m-5">
        <Button className="mx-4 px-5 py-3" variant="secondary" onClick={handleCancel} data-testid='promocode-cancel-btn'>Cancel</Button>
        <Button className="px-5 py-3" variant="primary" onClick={handleSubmit} data-testid='promocode-submit-btn'>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PromoCodeForm;
