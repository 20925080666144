import axios, { AxiosResponse, HttpStatusCode, RawAxiosRequestHeaders } from 'axios';
import { TLocationConfiguration } from '../models';

export class FacilityConfigService {
  private readonly apiKey: string;
  private readonly contentType: string;
  private readonly url: string;

  constructor() {
    this.apiKey = String(process.env.REACT_APP_PURCHASE_API_KEY);
    this.contentType = String(process.env.REACT_APP_CONTENT_TYPE);
    this.url = `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_PURCHASE_API_URL}`;
  }

  async getFacilitiesConfiguration(): Promise<{ success: boolean, data: AxiosResponse }> {
    const response = await axios.get(
      `${this.getUrl()}/config`,
      { headers: await this.getRequestHeaders() }
    );
    if (response.status === HttpStatusCode.Ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: response?.data };
  }

  async updateFacilitiesConfiguration(data: TLocationConfiguration[]): Promise<{ success: boolean, data: AxiosResponse }> {
    const response = await axios.put(
      `${this.getUrl()}/config`,
      { facilityInformation: data },
      { headers: await this.getRequestHeaders() }
    );
    if (response.status === HttpStatusCode.Ok) {
      return { success: true, data: response.data };
    }
    return { success: false, data: response?.data };
  }

  private async getRequestHeaders(): Promise<(RawAxiosRequestHeaders)> {
    return {
      'Content-Type': this.getContentType(),
      'x-api-key': this.getApiKey()
    };
  }

  private getApiKey(): string {
    return this.apiKey;
  };

  private getContentType(): string {
    return this.contentType;
  };

  private getUrl(): string {
    return this.url;
  };
}