import { useLayoutEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_Row, useMaterialReactTable } from 'material-react-table';
import FacilityService from '../services/FacilityService';
import PopupService from '../services/PopupService';
import { IFacilityFundingSourceData, Product } from '../models';
import { BundleUpdateResponse, ButtonLabel, Defaults, FundingSource, LoaderLabel, PlanAction, PlanStatus, Utils, errorMessages } from '../helpers';

type priceTablePropType = {
  facilitiesDataProp: [IFacilityFundingSourceData[], React.Dispatch<React.SetStateAction<IFacilityFundingSourceData[]>>];
  planName: string;
  planClassName: string;
  fundingSource: FundingSource;
  selectedRegion: string;
  setError: React.Dispatch<React.SetStateAction<{ hasError: boolean; message: string; }>>;
};

const PlanProcessingIcon = () => <div className='processing my-2'></div>;
const PlanAvailableIcon = ({ planClassName }: { planClassName: string }) => <span><i className={`bi ${planClassName}-icon bi-check-circle-fill`} /></span>;
const PlanUnAvailableIcon = () => <span><i className='danger-icon bi-x-circle-fill' /></span>;
const PlanStatusIcon = ({ cell, planClassName }: { cell: MRT_Cell<IFacilityFundingSourceData>, planClassName: string }) => {
  if (cell.getValue() === PlanStatus.PROCESSING) return <PlanProcessingIcon />;
  else if (cell.getValue() === PlanStatus.ACTIVE) return <PlanAvailableIcon planClassName={planClassName} />;
  else return <PlanUnAvailableIcon />;
};

const ButtonElement = ({ label, handleAction, row }: { label: string, handleAction?: any, row?: any }) => label === ButtonLabel.UNAVAILABLE ? <button className='link red' title='Please check MoSo configuration and migrate accordingly'>{label}</button> : <Button onClick={() => handleAction(row)} data-testid='button-element'>{label}</Button>;
const ProcessingElement = ({ label }: { label: string }) => <div className='loading' data-testid='processing-element'>{label}</div>;
const ActionIcon = ({ row, handleAction }: { row: MRT_Row<IFacilityFundingSourceData>, handleAction: (row: MRT_Row<IFacilityFundingSourceData>) => Promise<void> }) => {
  const { planStatus, loaderLabel, buttonLabel } = row.original;
  return (row.original.buttonLabel === ButtonLabel.UNAVAILABLE) ?
    <ButtonElement label={ButtonLabel.UNAVAILABLE} /> :
    <div data-testid='update-product-action-button'>
      {planStatus === PlanStatus.PROCESSING ? <ProcessingElement label={loaderLabel} /> : <ButtonElement label={buttonLabel} handleAction={handleAction} row={row} />}
    </div>
};

const PriceTable = ({ facilitiesDataProp: [facilitiesData, setFacilitiesData], planName, planClassName, fundingSource, selectedRegion, setError }: priceTablePropType) => {
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  /**
   * Enable / Disable plan
   * @param row 
   */
  const handleAction = async (row: MRT_Row<IFacilityFundingSourceData>): Promise<void> => {
    const { index, original: { id, loaderLabel, buttonLabel, name, planStatus } } = row;
    updateFacilityPlanStatus(index, PlanStatus.PROCESSING);
    try {
      const payload = { location: id, productName: planName, bundleName: fundingSource, action: planStatus === PlanStatus.INACTIVE ? PlanAction.ACTIVATE : PlanAction.DEACTIVATE };
      const facility = { id, name };
      const updateBundleStatusResponse = await FacilityService.updateBundleStatus(payload);
      if (!updateBundleStatusResponse.success) {
        if (updateBundleStatusResponse.message?.includes(BundleUpdateResponse.NOT_FOUND)) {
          PopupService.showToast('error', 'Product not present for facility.', 'Action button will be disabled.');
          updateFacilityButtonLabel(index, ButtonLabel.UNAVAILABLE);
        }
        updateFacilityPlanStatus(index, loaderLabel === LoaderLabel.DISABLING ? PlanStatus.ACTIVE : PlanStatus.INACTIVE);
      } else {
        if (loaderLabel === LoaderLabel.ENABLING) {
          const updatedData = await FacilityService.getFacilityData(facility, selectedRegion, planName);
          updateFacilityData(index, updatedData[fundingSource]);
        } else if (loaderLabel === LoaderLabel.DISABLING) {
          updateFacilityData(index, new Product(facility, selectedRegion));
        }
        updateFacilityLoaderLabel(index, loaderLabel === LoaderLabel.DISABLING ? LoaderLabel.ENABLING : LoaderLabel.DISABLING);
        updateFacilityButtonLabel(index, buttonLabel === ButtonLabel.DISABLE ? ButtonLabel.ENABLE : ButtonLabel.DISABLE);
      }
    } catch (error) {
      setError({ hasError: true, message: errorMessages.updatingBundleStatus });
    }
  }

  const columns = useMemo<MRT_ColumnDef<IFacilityFundingSourceData>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        muiTableHeadCellProps: { align: 'left' },
        muiTableBodyCellProps: { align: 'left' },
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' },
        size: 190
      },
      {
        accessorKey: 'id',
        header: 'ID',
        size: 100,
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' }
      },
      {
        accessorKey: 'region',
        header: 'Region',
        size: 160,
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' }
      },
      {
        accessorKey: 'planStatus',
        header: 'Enabled',
        enableColumnFilter: true,
        enableSorting: true,
        size: 110,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' },
        Cell: ({ cell }) => <PlanStatusIcon cell={cell} planClassName={planClassName} />
      },
      {
        header: 'Bundle Prices',
        columns: [
          {
            accessorKey: 'bundleMonthlyFee',
            header: 'Monthly',
            enableColumnFilter: true,
            enableSorting: true,
            size: 110
          },
          {
            accessorKey: 'bundleStartupFee',
            header: 'Startup',
            enableColumnFilter: true,
            enableSorting: true,
            size: 110
          }
        ]
      },
      {
        accessorKey: 'defaultPromotion',
        header: 'Default Promotion',
        size: 350,
        enableColumnFilter: true,
        enableSorting: true
      },
      {
        header: 'Discounted Prices',
        columns: [
          {
            accessorKey: 'promotionMonthlyFee',
            header: 'Monthly',
            enableColumnFilter: true,
            enableSorting: true,
            size: 110
          },
          {
            accessorKey: 'promotionStartupFee',
            header: 'Startup',
            enableColumnFilter: true,
            enableSorting: true,
            size: 110
          }
        ]
      },
      {
        header: 'Actions',
        size: 100,
        Cell: ({ row }) => <ActionIcon row={row} handleAction={handleAction} />
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Update plan status of facility at given index
   * @param index 
   * @param planStatus 
   */
  const updateFacilityPlanStatus = (index: number, planStatus: PlanStatus): void => {
    setFacilitiesData(prevState => {
      const updatedState = [...prevState];
      updatedState[index].planStatus = planStatus;
      return updatedState;
    });
  };

  /**
   * Update loader label of facility at given index
   * @param index 
   * @param loaderLabel 
   */
  const updateFacilityLoaderLabel = (index: number, loaderLabel: LoaderLabel): void => {
    setFacilitiesData(prevState => {
      const updatedState = [...prevState];
      updatedState[index].loaderLabel = loaderLabel;
      return updatedState;
    });
  };

  /**
   * Update button label of facility at given index
   * @param index 
   * @param buttonLabel 
   */
  const updateFacilityButtonLabel = (index: number, buttonLabel: ButtonLabel): void => {
    setFacilitiesData(prevState => {
      const updatedState = [...prevState];
      updatedState[index].buttonLabel = buttonLabel;
      return updatedState;
    });
  };

  /**
   * Update funding source data of facility at given index
   * @param index 
   * @param fundingSourceData 
   */
  const updateFacilityData = (index: number, fundingSourceData: IFacilityFundingSourceData): void => {
    setFacilitiesData(prevState => {
      const updatedState = [...prevState];
      updatedState[index] = fundingSourceData;
      return updatedState;
    });
  };

  /** Clear all the selected rows */
  const handleClearSelection = (): void => {
    table.resetRowSelection();
  }

  /** Disable bundles for selected rows */
  const handleDisableBundles = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const rowsToBeEnabled = selectedRows?.filter(({ original: { planStatus } }) => planStatus === PlanStatus.ACTIVE);
    if (rowsToBeEnabled?.length) {
      for (let row of rowsToBeEnabled) {
        await handleAction(row);
      }
    }
    table.resetRowSelection();
  };

  /** Enable bundles for selected rows */
  const handleEnableBundles = async () => {
    const selectedRows = table.getSelectedRowModel().rows;
    const rowsToBeDisabled = selectedRows?.filter(({ original: { planStatus } }) => planStatus === PlanStatus.INACTIVE);
    if (rowsToBeDisabled?.length) {
      for (let row of rowsToBeDisabled) {
        await handleAction(row);
      }
    }
    table.resetRowSelection();
  };

  const table = useMaterialReactTable({
    columns,
    data: facilitiesData,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableBottomToolbar: false,
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enablePagination: false,
    enableRowSelection: (row) => row.original.buttonLabel !== ButtonLabel.UNAVAILABLE,
    getRowId: (originalRow) => `${originalRow.id}#${planName}#${fundingSource}`,
    muiTableBodyCellProps: { align: 'center' },
    muiTableHeadCellProps: { align: 'center' },
    muiTablePaperProps: { sx: { boxShadow: 0 } },
    muiTableProps: { sx: { tableLayout: 'fixed', boxShadow: 0 } },
    muiTableContainerProps: { sx: { maxHeight: tableHeight } },
    positionToolbarAlertBanner: 'top',
    renderTopToolbarCustomActions: ({ table }) => (
      <div className='toolbar-actions'>
        <div className='selected'>{!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() && <span>No row selected</span>}</div>
        <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={handleClearSelection} variant='primary' className='me-3'>Clear Selection</Button>
        <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={handleDisableBundles} variant='danger' className='me-3'>Disable Bundles</Button>
        <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={handleEnableBundles} variant='success'>Enable Bundles</Button>
      </div>
    ),
    state: { density: 'compact' }
  });

  return <MaterialReactTable table={table} />
}
export default PriceTable;
