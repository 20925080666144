  import { createSlice } from '@reduxjs/toolkit';
import { PromoCodeEvents, Utils } from '../helpers';
import { IPromoCode, TEvent, PromoCode } from '../models';

export type PromoCodesState = {
  items: IPromoCode[];
  promoCode: any;
  event: TEvent;
  changed: boolean;
}
const initialState: PromoCodesState = { items: [], changed: false, promoCode: {...new PromoCode()}, event: PromoCodeEvents.view };

const promoCodesSlice = createSlice({
  name: 'promocodes',
  initialState,
  reducers: {
    replacePromoCodes(state, action) {
      const promoCodes = action.payload.map((promoCode: IPromoCode) => {
        promoCode.status = Utils.getPromoCodeStatus(promoCode);
        return promoCode;
      });      
      state.items = promoCodes;
    },
    updateList(state, action) {
      const promoCode = action.payload;
      const existingPromoCode = state.items.find((item) => item.promoCode === promoCode.promoCode);
      state.changed = true;
      if (!existingPromoCode) {
        const newPromoCode = promoCode;
        newPromoCode.promoCode = promoCode.promoCode;
        newPromoCode.endDate = promoCode.endDate;
        newPromoCode.maxUseCount = promoCode.maxUseCount;
        newPromoCode.mosoPromoName = promoCode.mosoPromoName;
        newPromoCode.promoMosoId = promoCode.promoMosoId;
        newPromoCode.startDate = promoCode.startDate;
        newPromoCode.status = Utils.getPromoCodeStatus(newPromoCode);
        newPromoCode.useCount = 0;
        state.items.unshift(newPromoCode);
      } else {
        existingPromoCode.promoCode = promoCode.promoCode;
        existingPromoCode.endDate = promoCode.endDate || Utils.getFormattedDate();
        existingPromoCode.maxUseCount = promoCode.maxUseCount;
        existingPromoCode.mosoPromoName = promoCode.mosoPromoName;
        existingPromoCode.promoMosoId = promoCode.promoMosoId;
        existingPromoCode.startDate = promoCode.startDate;
        existingPromoCode.status = Utils.getPromoCodeStatus(existingPromoCode);
      }
    },
    createPromoCodeEvent(state) {
      state.promoCode = { ...new PromoCode() };
      state.event = PromoCodeEvents.create;
    },
    updatePromoCodeEvent(state, action) {
      state.promoCode = {...new PromoCode(action.payload)};
      state.event = PromoCodeEvents.update;
    },
    expirePromoCodeEvent(state, action) {
      state.promoCode = { ...new PromoCode(action.payload)};
      state.event = PromoCodeEvents.expire;
    }
  }
});
export const promoCodesActions = promoCodesSlice.actions;

export default promoCodesSlice.reducer;