import { configureStore } from '@reduxjs/toolkit';
import clubsReducer, { ClubsState } from './clubs-slice';
import locationsConfigReducer, { LocationsConfigState } from './locations-config-slice';
import promoCodesReducer, { PromoCodesState } from './promo-code-slice';

const store  = configureStore({
  reducer: {
    clubs: clubsReducer,
    locationsConfig: locationsConfigReducer,
    promoCodes: promoCodesReducer
  }
})

export type TStoreStates = {
  clubs: ClubsState;
  locationsConfig: LocationsConfigState;
  promoCodes: PromoCodesState;
}

export default store;