import { Auth } from 'aws-amplify';
import axios, { AxiosResponse } from "axios";
import { AgreementGroupId } from '../helpers';
import { IProductPrices, IProduct, IUpdatePlanPayload, IProductVariations } from "../models";

const headers = {
  'Content-Type': 'application/json',
  'x-api-key': String(process.env.REACT_APP_CATALOG_KEY)
}
const versionOne = 'v1';
const versionTwo = 'v2';

const ProductService = {
  productCatalogURL: `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_CATALOG_URL}`,
  async getPrices(facilityId: string): Promise<IProductPrices> {
    const url = `${ProductService.productCatalogURL}/${versionOne}/locations/${facilityId}/product/variations/prices`;
    const response = await axios.get(url, { headers });
    return response.data;
    
  },
  async getPricesByPlanName(locationId: string, planName: string): Promise<IProductPrices> {
    const url = `${ProductService.productCatalogURL}/${versionOne}/locations/${locationId}/product/variations/prices`;
    const response = await axios.get(url, { headers, params: { planName } });
    return response.data;
  },
  async getBundleDetails(locationId: string, bundleId: string): Promise<IProduct> {
    const url = `${ProductService.productCatalogURL}/${versionTwo}/locations/${locationId}/product/${bundleId}`;
    const response = await axios.get(url, { headers });
    return response.data;
  },
  async requestMigrateProducts(facilityId: string): Promise<{ message: string }> {
    const url = `${ProductService.productCatalogURL}/${versionOne}/requestMigrateProductsByFacility`;
    const response = await axios.post(url, { facilityId, agreementGroupId: AgreementGroupId.INDIVIDUAL }, { headers });
    return response.data;
  },
  async updateBundleStatus(payload: IUpdatePlanPayload): Promise<AxiosResponse> {
    const data = await Auth.currentSession()
    const idToken = data.getIdToken().getJwtToken();
    const updatedHeaders = { ...headers, 'Authorization': `Bearer ${idToken}` }
    const url = `${ProductService.productCatalogURL}/${versionOne}/updateBundleStatus`;
    const response = await axios.post(url, { ...payload }, { headers: updatedHeaders });
    return response;
  },

  /**
   * Get facility plan variations
   * @param facilityId
   * @returns IProductVariations
   */
  async getVariationByFacility(facilityId: string): Promise<IProductVariations> {
    const url = `${this.productCatalogURL}/${versionOne}/locations/${facilityId}/product/variations`;
    const response = await axios.get(url, { headers });
    return response.data;
  }
};

export default ProductService;