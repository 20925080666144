import { IPromoCodeEvents, TLocationsConfigEvents } from "../models";

export enum Timezone {
  Eastern = 'America/New_York'
}

export enum Action {
  Create = 'create',
  Expire = 'expire',
  Update = 'update'
}

export enum MigrationStatus {
  FAILED = 'Failed',
  MIGRATE = 'Migrate',
  MIGRATED = 'Migrated',
  MIGRATING = 'Migrating'
}

export enum PlanStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PROCESSING = 'Processing'
}

export enum ButtonLabel {
  DISABLE = 'Disable',
  ENABLE = 'Enable',
  UNAVAILABLE = 'Unavailable'
}

export enum LoaderLabel {
  DISABLING = 'Disabling',
  ENABLING = 'Enabling',
}

export enum PlanAction {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate'
}

export enum HttpStatusCode {
  OK = 200,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500
}

export enum BundleUpdateResponse {
  NOT_FOUND = 'not found',
}

export enum ComparisonStatus {
  COMPARE = 'compare',
  COMPARING = 'comparing',
  DIFFERENT = 'different',
  IDENTICAL = 'identical'
}

export const plans = new Map<string, { name: string, id: number, shortName: string }>([
  ['BLUE', { name: 'Blue', id: 6, shortName: 'blue' }],
  ['GREEN', { name: 'Green', id: 8, shortName: 'green' }],
  ['GRAY', { name: 'Gray', id: 10, shortName: 'gray' }],
  ['ORANGE', { name: 'Orange', id: 1046, shortName: 'orange' }],
  ['BLUE_NON-COMMIT', { name: 'Blue_Non-Commit', id: 1082, shortName: 'blue' }]
]);

export enum FundingSource {
  BANK_ACCOUNT = 'ACH',
  CREDIT_CARD = 'CC'
}

export const PromoCodeEvents: IPromoCodeEvents = {
  create: {
    title: 'create promo code',
    text: 'Create a new promo code by entering the required information.',
    type: 'create'
  },
  expire: {
    title: 'expire promo code',
    text: 'Set the expire date of the promo code by selecting the date.',
    type: 'expire'
  },
  update: {
    title: 'update promo code',
    text: 'Update the promo code by changing the values.',
    type: 'update'
  },
  view: {
    title: 'promo codes',
    text: 'promo Codes list.',
    type: 'view'
  }
}

export const LocationsConfigEvents: TLocationsConfigEvents = {
  create: {
    title: 'create location configuration',
    text: 'Create a new location configuration by entering the required information.',
    type: 'create'
  },
  update: {
    title: 'update location configuration',
    text: 'Update the location configuration code by changing the values.',
    type: 'update'
  },
  view: {
    title: 'locations configuration',
    text: 'locations list',
    type: 'view'
  }
}

export enum Defaults {
  EndDate = '12-31-2040',
  MaxUseCount = 10000,
  PromoMosoId = 0,
  Region = 'VA',
  ProductNotExist = 'The product does not exist in the catalog',
  TableHeight = 400
}

export const promoCodesLogsColumnsConfig = [
  {
    title: 'MemberId', field: 'memberId',
    cellStyle: {
      paddingLeft: '24px',
      textAlign: 'left',
      width: '15%'
    },
    headerStyle: {
      paddingLeft: '24px',
      textAlign: 'left'
    }
  },
  {
    title: 'Name', field: 'memberName',
    cellStyle: {
      textAlign: 'left',
      width: '25%'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Email', field: 'memberEmail',
    cellStyle: {
      textAlign: 'left',
      width: '30%'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Transaction Date', field: 'transactionDate',
    cellStyle: {
      textAlign: 'left',
      width: '15%'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Transaction Time', field: 'transactionTime',
    cellStyle: {
      width: '15%'
    },
    headerStyle: {
      textAlign: 'right'
    }
  }
];

export const referralsColumnsConfig = [
  {
    title: 'Referee Email', field: 'referralEmail', sorting: false,
    cellStyle: {
      paddingLeft: '24px',
      textAlign: 'left'
    },
    headerStyle: {
      paddingLeft: '24px',
      textAlign: 'left'
    }
  },
  {
    title: 'ReferrerId', field: 'RefereeMoSoID', sorting: false,
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Status', field: 'referralStatusTitle',
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'right'
    }
  },
  {
    title: 'Location', field: 'location',
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'right'
    }
  },
  {
    title: 'Created on', field: 'referralCreatedDate',
    cellStyle: {
      fontSize: '12px',
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'First Name', field: 'referralFirstName',
    cellStyle: {
      textAlign: 'left'
    },
    headerStyle: {
      textAlign: 'left'
    }
  },
  {
    title: 'Last Name', field: 'referralLastName',
    cellStyle: {
      // width: '10%'
    },
    headerStyle: {
      textAlign: 'right'
    }
  },
  {
    title: 'RefereeId', field: 'referralMosoID', hidden: true,
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'Reward($)', field: 'rewardPrice',
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'Expires on', field: 'rewardExpirationDate',
    cellStyle: {
      fontSize: '12px',
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'Getting Price on', field: 'rewardPriceGettingDate', hidden: true,
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'Joined Email Sent', field: 'friendJoinedEmailSent', hidden: true,
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  },
  {
    title: 'Written by', field: 'writtenBy', hidden: true,
    cellStyle: {
      textAlign: 'center'
    },
    headerStyle: {
      textAlign: 'center'
    }
  }
];
