export const errorMessages = {
  clubsUpdateFailed: 'Failed to update the clubs images.',
  fetchingRegionData: 'Error occurred while fetching selected region data',
  generic: 'Something went wrong! Please try again.',
  imageUploadFailed: 'Failed to upload the images.',
  invalidSession: 'Your session has been expired. Please sign-in again.',
  underConstruction: 'This feature is under construction and will be available soon.',
  updatingBundleStatus: 'Error occurred while updating bundle status'
}

export const responseMessages = {
  productNotExists: 'The product does not exist in the catalog',
  screenSaverUpdated: 'Screensaver updated successfully',
  changeConfigurationInitiated: 'Changes to the configurations have been successfully initiated.<br/> It can take up to <strong>5-10 minutes</strong> to complete the deployment in order to be reflected'
}