import axios, { AxiosError } from 'axios';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { locationsConfigActions } from './locations-config-slice';
import { FacilityConfigService } from '../services/FacilityConfigService';
import PopupService from '../services/PopupService';
import { StorageFactory } from '../services/StorageFactory';
import { errorMessages, responseMessages } from '../helpers';
import { TLocationConfiguration } from '../models';

export const fetchLocations = () => {
  PopupService.showLoader('Getting the locations configuration');
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const service = new FacilityConfigService();
      const response = await service.getFacilitiesConfiguration();
      if (!response.success) { throw new Error('Unable to fetch the locations configuration'); }
      const locations = Object.values(response.data);
      dispatch(locationsConfigActions.replaceLocationsConfig(locations || []));
      StorageFactory(sessionStorage).setItem('LOCATIONS_CONFIG', JSON.stringify(locations));
      PopupService.hideLoader();
    } catch (error: any) {
      if(axios.isAxiosError(error) && error.code === AxiosError.ERR_NETWORK){
        PopupService.showInfo(errorMessages.underConstruction, 'Coming Soon');
      } else {
        PopupService.showError(error.response?.data.message || errorMessages.generic);
      }
    }
  };
};

export const updateLocations = (locationsConfig: TLocationConfiguration[]) => {
  PopupService.showLoader('Saving the locations configuration');
  return async () => {
    try {
      const service = new FacilityConfigService();
      const { success } = await service.updateFacilitiesConfiguration(locationsConfig);
      if (!success) { throw new Error('Unable to save the locations configuration'); }
      PopupService.showSuccess(responseMessages.changeConfigurationInitiated);
    } catch (error) {
      const errorMessage = axios.isAxiosError(error) && !error.response?.data.success
        ? error.response?.data.message
        : errorMessages.generic;
      PopupService.showError(errorMessage);
    }
  };
};
