import Swal from "sweetalert2";

const PopupService = {
  showInfo: function (message: string, title = 'Info'): void {
    Swal.close();
    Swal.fire({
      title,
      html: message,
      icon: 'info',
      showConfirmButton: true
    });
  },
  showLoader: function (message: string) {
    Swal.close();
    Swal.fire({
      title: message,
      icon: 'info',
      showClass: { popup: '', icon: '' },
      hideClass: { popup: '' },
      showConfirmButton: false,
      html: '<div>Please wait.</div>',
      allowOutsideClick: false
    });
    Swal.showLoading();
  },
  showError: function (message: string) {
    Swal.close();
    Swal.fire({
      title: 'Error',
      icon: 'error',
      html: message,
      allowOutsideClick: false,
      width: 600,
      confirmButtonText: 'Ok',
      confirmButtonAriaLabel: 'Ok'
    });
  },
  showSuccess: function (message: string) {
    Swal.close();
    Swal.fire({
      title: 'Success',
      icon: 'success',
      html: message
    });
  },
  showWarning: function (message: string) {
    Swal.close();
    Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      showConfirmButton: true
    });
  },
  hideLoader: function () {
    Swal.close();
  },
  updateLoader: function (title: string, time = 0) {
    setTimeout(() => {
      Swal.update({ title });
      Swal.showLoading();
    }, time);
  },
  showToast: function (iconType: 'error' | 'success' | 'info' | 'question', header: string, message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      width: '385px',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: iconType,
      title: header,
      text: message
    })
  }
}

export default PopupService;