import { TLocationConfiguration } from "../models";

export const priceColumnsConfig = [
  {
    header: 'Club',
    enableColumnActions: false,
    columns: [
      {
        header: 'BUNDLES >>>',
        columns: [
          {
            header: 'Name',
            accessorKey: 'geomodifier',
            enableColumnActions: false,
            size: 100,
            muiTableHeadCellProps: {
              align: 'left'
            },
            muiTableBodyCellProps: {
              align: 'left'
            },
          }
        ]
      }
    ]
  },
  {
    header: 'Green',
    enableColumnActions: false,
    size: 200,
    columns: [
      {
        header: 'ACH',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.green.ach.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.green.ach.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.green.ach.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }]
      },
      {
        header: 'CC',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.green.cc.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.green.cc.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.green.cc.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }
        ]
      }
    ]
  },
  {
    header: 'Blue',
    enableColumnActions: false,
    size: 200,
    columns: [
      {
        header: 'ACH',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.blue.ach.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.blue.ach.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.blue.ach.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }]
      },
      {
        header: 'CC',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.blue.cc.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.blue.cc.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.blue.cc.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }
        ]
      }
    ]
  },
  {
    header: 'Gray',
    enableColumnActions: false,
    size: 200,
    columns: [
      {
        header: 'ACH',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.gray.ach.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.gray.ach.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.gray.ach.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }]
      },
      {
        header: 'CC',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.gray.cc.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.gray.cc.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.gray.cc.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }
        ]
      }
    ]
  },
  {
    header: 'Orange',
    enableColumnActions: false,
    size: 200,
    columns: [
      {
        header: 'ACH',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.orange.ach.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.orange.ach.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.orange.ach.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }]
      },
      {
        header: 'CC',
        columns: [
          {
            header: 'Join',
            accessorKey: 'prices.orange.cc.startup.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          },
          {
            header: 'ROM',
            accessorKey: 'prices.orange.cc.monthly.amount',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            },
          },
          {
            header: 'Monthly',
            accessorKey: 'prices.orange.cc.monthly.unitPrice',
            enableColumnActions: false,
            enableSorting: false,
            size: 50,
            muiTableHeadCellProps: {
              align: 'center',
            },
            muiTableBodyCellProps: {
              align: 'center',
            }
          }
        ]
      }
    ]
  },
];

export const promoCodesColumnsConfig = [
  {
    header: 'Promocode',
    accessorKey: 'promoCode',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'left',
    },
    muiTableBodyCellProps: {
      align: 'left'
    }
  },
  {
    header: 'MoSo Promo Name',
    accessorKey: 'mosoPromoName',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'left',
    },
    muiTableBodyCellProps: {
      align: 'left'
    }
  },
  {
    header: 'MoSo PromoId',
    accessorKey: 'promoMosoId',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  },
  {
    header: 'Start Date',
    accessorKey: 'startDate',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  },
  {
    header: 'End Date',
    accessorKey: 'endDate',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  },
  {
    header: 'Max Use Count',
    accessorKey: 'maxUseCount',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  },
  {
    header: 'Use Count',
    accessorKey: 'useCount',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  },
  {
    header: 'Status',
    accessorKey: 'status',
    enableColumnActions: false,
    size: 80,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center'
    }
  }
];

export const locationsColumnsConfig = [
  {
    accessorKey: 'name',
    header: 'Name',
    muiTableHeadCellProps: {
      align: 'left',
    },
    muiTableBodyCellProps: {
      align: 'left',
    },
    enableColumnFilter: true,
    enableEditing: false
  },
  {
    accessorKey: 'location',
    header: 'ID',
    size: 60,
    enableColumnFilter: true,
    enableEditing: false
  },
  {
    accessorFn: (row: TLocationConfiguration) => row.adyenInformation?.environment || '-',
    header: 'Environment',
    size: 80,
    enableColumnFilter: true
  },
  {
    accessorFn: (row: TLocationConfiguration) => row.adyenInformation?.locale || '-',
    header: 'Locale',
    size: 80,
    enableColumnFilter: true
  },
  {
    accessorFn: (row: TLocationConfiguration) => row.adyenInformation?.merchantAccount || '-',
    header: 'Merchant Account',
    size: 100,
    enableColumnFilter: true
  },
  {
    accessorFn: (row: TLocationConfiguration) => row.adyenInformation?.clientKey || '-',
    header: 'Client Key',
    enableColumnFilter: true,
    enableSorting: false
  },
];